
import { defineComponent, onMounted, ref } from "vue";
import Rating from "./Rating.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Modal } from "bootstrap";
import { hideModal, showModal } from "@/core/helpers/dom";
import axios from "axios";
import router from "@/router";
import { useRoute, useRouter } from "vue-router";
const video = ref();
const canvas = ref();
const captures: any = ref([]);
const isCameraOpen = ref(false);
const listBlogs = ref();
const listCourses = ref();
const listSyllabuses = ref();
const listCoursesNew = ref();
const listCertificate = ref();
const listExamCenter = ref();
const listExamPin = ref();
const roomcode = ref();
const roomPwd = ref();
const flgPhoto = ref();
const modalPassword = ref<null | HTMLElement>(null);
export default defineComponent({
  name: "home",
  components: {
    Rating,
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      passwordFieldType: "password",
      sizeStatistic: null,
    };
  },
  setup() {
    /* eslint-disable */
    const route = useRoute();
    const router = useRouter();
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
      router.go(0);
    }
    onMounted(async () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getNews" +
            "?sizeContents=3&page=0&orderBy=1&sort=1&enable=1",
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then(({ data }) => {
          // console.log("getNews");
          // console.log(data.data.content);
          listBlogs.value = data.data.content;
        })
        .catch(({ error }) => {
          // console.log(error);
        });
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getCourse" +
            "?sizeContents=3&page=0&orderBy=2&sort=1&enable=1&flgGeneral=1",
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then(({ data }) => {
          // console.log("getCourse");
          // console.log(data.data.content);
          listCourses.value = data.data.content;
        })
        .catch(({ error }) => {
          // console.log(error);
        });
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getCourse" +
            "?sizeContents=3&page=0&orderBy=2&sort=3&enable=1&flgGeneral=1",
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then(({ data }) => {
          // console.log("listCoursesNew");
          // console.log(data);
          listCoursesNew.value = data.data.content;
        })
        .catch(({ error }) => {
          // console.log(error);
        });
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getSyllabus?&sizeContents=4&page=0&orderBy=1&sort=1&enable=1",
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then(({ data }) => {
          // console.log("listSyllabuses");
          // console.log(data.data.content);
          listSyllabuses.value = data.data.content;
        })
        .catch(({ error }) => {
          // console.log(error);
        });
      if (localStorage.getItem("id_token") != null) {
        getExamRoomRegister();
      }
      await getExam();
    });
    const getExam = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getExam" +
            "?sizeContents=3&page=0&orderBy=2&sort=1&enable=1&examType=1",
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then(({ data }) => {
          // console.log("getExamPin");
          // console.log(data.data.content);
          listExamPin.value = data.data.content;
        })
        .catch(({ error }) => {
          // localStorage.clear();
          router.push({ name: "sign-in" });
        });
    };
    const registerSyllabus = (id, register, status) => {
      if (status == "true") {
        router.push({ name: "mandatory-list", params: { id: id } });
      } else {
        Swal.fire({
          title: "เงื่อนไขการผ่านหลักสูตร",
          html:
            "ระบบจะจับเวลาเพื่อนับถอยหลังการเรียน<br>เมื่อเรียนจบต้องกรอกข้อมูลเพื่อยืนยันตัวตนก่อนการจองห้องสอบ<br>เพื่อขอใบรับรอง",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#323563",
          cancelButtonColor: "#d33",
          confirmButtonText: "ลงทะเบียน",
          allowOutsideClick: false,
          cancelButtonText: "ยกเลิก",
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (localStorage.getItem("id_token")) {
              if (register == true) {
                router.push({ name: "mandatory-list", params: { id: id } });
              } else {
                const formData = new FormData();
                formData.append("syllabusId", id);
                await axios
                  .post(
                    process.env.VUE_APP_API_URL + "/registerSyllabus",
                    formData,
                    {
                      headers: {
                        token: localStorage.getItem("id_token")
                          ? localStorage.getItem("id_token")
                          : localStorage.getItem("tokenGuest"),
                      },
                    }
                  )
                  .then(({ data }) => {
                    router.push({ name: "mandatory-list", params: { id: id } });
                  })
                  .catch(({ error }) => {
                    // console.log(error);
                  });
              }
            } else {
              router.push({ name: "sign-in" });
            }
          }
        });
      }
    };
    const getCertificateId = async (id) => {
      const certificate = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getCourseRegister?courseId=" +
          id +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          },
        }
      );
      // console.log(certificate.data.data.content[0]);
      listCertificate.value = certificate.data.data.content[0];
      downloadCertificate();
    };
    const downloadCertificate = async () => {
      const director = await axios.get(
        process.env.VUE_APP_API_URL + "/getDirector",
        {
          headers: {
            token: localStorage.getItem("id_token"),
          },
        }
      );
      // console.log(
      //   director.data.data.firstName + " " + director.data.data.lastName
      // );
      let date: String =
        new Date(listCertificate.value.certApproveDate).toLocaleDateString(
          "th-TH",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ) + "";
      const url =
        process.env.VUE_APP_REPORT_URL +
        "&title=" +
        listCertificate.value.authorProfile.prefix +
        "&course=" +
        listCertificate.value.courseProfile.title +
        "&firstname=" +
        listCertificate.value.authorProfile.firstName +
        "&lastname=" +
        listCertificate.value.authorProfile.lastName +
        "&date=" +
        arabic_to_thai(date) +
        "&license=" +
        process.env.VUE_APP_IMG_URL +
        director.data.data.signature +
        "&licenseName=" +
        director.data.data.firstName +
        " " +
        director.data.data.lastName +
        "&position=" +
        director.data.data.position;
      window.open(url.replace("/fileName", "/cert_dede2.pdf"), "_blank");
    };
    const getExamRoomRegister = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getExamRoomRegister" +
            "?sizeContents=1&page=0&status=0,1&isDetail=true&userId=" +
            localStorage.getItem("u_id") +
            "&startDateEnter=",
          {
            headers: {
              token: localStorage.getItem("id_token"),
            },
          }
        )
        .then(({ data }) => {
          // console.log("getExamRoomRegister");
          let list: any = [];
          for (const loopData of data.data.content) {
            if (loopData.startTestDate != null) {
              if (
                new Date(loopData.startTestDate).getTime() >
                new Date().setHours(0, 0, 0)
              ) {
                list.push(loopData);
              }
            } else {
              if (
                new Date(loopData.examRoomProfile.endDate).getTime() >
                new Date().setHours(0, 0, 0)
              ) {
                list.push(loopData);
              }
            }
          }
          listExamCenter.value = list;
        })
        .catch(({ error }) => {
          // console.log(error);
          // localStorage.clear();
          router.push({ name: "sign-in" });
        });
    };
    const registerExamRoom = async (
      id,
      flgPwd,
      photo,
      examRoomCode,
      startDate
    ) => {
      if (startDate && new Date().getTime() >= new Date(startDate).getTime()) {
        roomcode.value = examRoomCode;
        flgPhoto.value = photo;
        if (flgPwd && photo) {
          const myModal = Modal.getInstance(modalPassword.value!);
          myModal?.show();
        } else if (flgPwd) {
          const myModal = Modal.getInstance(modalPassword.value!);
          myModal?.show();
        } else if (photo) {
          router.push({
            name: "verify",
            params: { examRoomCode: examRoomCode },
          });
        } else {
          const formData = new FormData();
          formData.append("examRoomCode", examRoomCode + "");
          await axios
            .post(
              process.env.VUE_APP_API_URL + "/enterExamRoomRegister",
              formData,
              {
                headers: {
                  token: localStorage.getItem("id_token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code == 200) {
                router.push({
                  name: "exam-detail",
                  params: { id: response.data.data.examRoomId },
                });
              } else {
                Swal.fire({
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "ลองใหม่ภายหลัง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-danger",
                  },
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                text: error.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "ลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      } else {
        Swal.fire({
          text: "ยังไม่ถึงเวลาเข้าสอบ",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "ลองใหม่ภายหลัง!",
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      }
    };
    const submitPassword = async () => {
      hideModal(modalPassword.value);
      const formData = new FormData();
      formData.append("examRoomCode", roomcode!.value + "");
      formData.append("examRoomPwd", roomPwd!.value + "");
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/checkExamRoomRegister",
          formData,
          {
            headers: {
              token: localStorage.getItem("id_token"),
            },
          }
        )
        .then(async (response) => {
          if (response.data.code == 200) {
            if (flgPhoto.value == true) {
              router.push({
                name: "verify",
                params: { examRoomCode: roomcode.value },
              });
            } else {
              enterRoom();
            }
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    const enterRoom = async () => {
      const formData = new FormData();
      formData.append("examRoomCode", roomcode!.value + "");
      formData.append("examRoomPwd", roomPwd!.value + "");
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/enterExamRoomRegister",
          formData,
          {
            headers: {
              token: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.code == 200) {
            router.push({
              name: "exam-detail",
              params: { id: response.data.data.examRoomId },
            });
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    const arabic_to_thai = (text) => {
    let data = text.replaceAll("1", "๑").replaceAll("2", "๒").replaceAll("3", "๓").replaceAll("4", "๔").replaceAll("5", "๕").replaceAll("6", "๖").replaceAll("7", "๗").replaceAll("8", "๘").replaceAll("9", "๙").replaceAll("0", "๐")
    return data
}
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      listBlogs,
      listCourses,
      listSyllabuses,
      registerSyllabus,
      listCoursesNew,
      listCertificate,
      getCertificateId,
      downloadCertificate,
      getExamRoomRegister,
      listExamCenter,
      registerExamRoom,
      getExam,
      listExamPin,
      modalPassword,
      showModal,
      hideModal,
      submitPassword,
      enterRoom,
      roomcode,
      roomPwd,
      flgPhoto,
      arabic_to_thai
    };
  },
  async mounted() {
    const modal_score = document.getElementById("kt_modal_password")!;
    const modal = new Modal(modal_score);
    const size = await axios.get(
      process.env.VUE_APP_API_URL + "/getSizeStatistic",
      {
        headers: {
          token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),
        },
      }
    );
    // console.log("getSizeStatistic");
    // console.log(size.data.data);
    this.sizeStatistic = size.data.data;
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    removeStyle(html) {
      const regex = /(style=".+?")/gm;
      const str = html;
      const subst = ``;
      const result = str.replace(regex, subst);
      return result;
    },
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    toggleCamera() {
      if (isCameraOpen.value) {
        isCameraOpen.value = false;
        this.stopCameraStream();
      } else {
        isCameraOpen.value = true;
        this.createCameraElement();
      }
      //  createCameraElement();
      //  stopCameraStream();
    },
    createCameraElement() {
      video.value = this.$refs.video;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            video.value.srcObject = stream;
            video.value.play();
          });
      }
    },
    stopCameraStream() {
      video.value = this.$refs.video;
      video.value = video.value.srcObject.getTracks();
      video.value.forEach((track) => {
        track.stop();
      });
    },
    capture() {
      /* eslint-disable */
      canvas.value = this.$refs.canvas;
      var context: any = canvas.value
        .getContext("2d")
        .drawImage(this.video, 0, 0, 640, 480);
      captures.value.push(canvas.value.toDataURL("image/png"));
      // this.captures.push(canvas.value.toDataURL("image/png"));
      /* eslint-disable */
    },
    renderCategory(list) {
      let data: any = [];
      for (const looplist of list) {
        data.push(looplist[0].data);
      }
      if (data.length > 0) {
        return data.join(",");
      } else {
        return "-";
      }
    },
    renderStatus(status) {
      if (status == 1 || status == 2) {
        return "<span style='color:#9999D3'>เรียนต่อ</span>";
      } else if (status == 3) {
        return "<span style='color:#31B67C'>เรียนจบ</span>";
      } else if (status == 4) {
        return "<span style='color:#FF7786'>ไม่ผ่านหลักสูตร</span>";
      } else if (status == 5) {
        return "<span style='color:#F2A17D'>หมดเวลาเรียน</span>";
      } else {
        return "-";
      }
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
    examOpen(id) {
      if (localStorage.getItem("id_token") != null) {
        this.$router.push({ name: "exam-general-detail", params: { id: id } });
      } else {
        this.$router.push({ name: "sign-in" });
      }
    },

    abbreviateNumber(number) {
      var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
      // what tier? (determines SI symbol)
      var tier = (Math.log10(Math.abs(number)) / 3) | 0;
      // if zero, we don't need a suffix
      if (tier == 0) return number;

      // get suffix and determine scale
      var suffix = SI_SYMBOL[tier];
      var scale = Math.pow(10, tier * 3);

      // scale the number
      var scaled = number / scale;

      // format number and add suffix
      return scaled.toFixed(1) + suffix;
    },
  },
});
